html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  overflow-x: hidden;
}
