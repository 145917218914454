.swiper-slide {
  opacity: 0.0;
  display: flex;
  pointer-events: none;
}

.swiper-slide-active {
  opacity: 1;
  justify-content: center;
}

.swiper-slide-prev {
  cursor: pointer;
  justify-content: flex-end;
}

.swiper-slide-next {
  cursor: pointer;
  justify-content: flex-start;
}

/* .swiper-container {
  overflow: visible;
} */
